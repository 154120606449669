<script setup>
import FluentGpt from 'core-ui/components/common/fluentGpt.vue'
import { useMusePanel } from 'core-ui/composables/useMusePanel'
const { museOpen, museModal, promptLoading, modalPosition } = useMusePanel()
const handleDragEnd = (event) => {
  const min = document.getElementById('muse-modal-minimize')
  const close = document.getElementById('muse-modal-close')
  if (event.target === min || event.target === close || min.contains(event.target) || close.contains(event.target)) {
    return
  }
  const left = event.pageX - event.layerX
  const top = event.pageY - event.layerY
  modalPosition.value = { left, top }
}
</script>
<template>
  <p-modal v-model:visible="museOpen"
           minX="70"
           minY="5"
           :closable="false"
           style="width: 516px;"
           :pt:root:style="`left: ${modalPosition.left}px; position: fixed; top: ${modalPosition.top}px;`"
           pt:content:class="p-0"
           @dragend="handleDragEnd">
    <template #header>
      <div class="mr-auto">Muse</div>
      <t-button-inline id="muse-modal-minimize" class="text-dark mr-4" :disabled="promptLoading" @click="museModal = false">
        <fluency-icon type="openModal" class="rotate-180"></fluency-icon>
      </t-button-inline>
      <t-button-inline id="muse-modal-close" class="text-dark" @click="museOpen = false">
        <fluency-icon type="close"></fluency-icon>
      </t-button-inline>
    </template>
    <fluent-gpt style="height: 60vh;"></fluent-gpt>
  </p-modal>
</template>

<style lang="scss" scoped>
.bg-white-really {
  background: #ffffff;
}
</style>
