<script setup>
import FluentGpt from 'core-ui/components/common/fluentGpt.vue'
import { useMusePanel } from 'core-ui/composables/useMusePanel'
const { museOpen, museModal, promptLoading } = useMusePanel()
</script>
<template>
  <div class="bg-white-really">
    <div class="d-flex-center justify-content-end" style="padding: var(--p-dialog-header-padding);">
      <div class="mr-auto">Muse</div>
      <t-button-inline class="text-dark mr-4" :disabled="promptLoading" @click="museModal = true">
        <fluency-icon type="openModal"></fluency-icon>
      </t-button-inline>
      <t-button-inline class="text-dark" @click="museOpen = false">
        <fluency-icon type="close"></fluency-icon>
      </t-button-inline>
    </div>
    <fluent-gpt class="bg-white-really rounded" style="height: calc(100vh - 62px);"></fluent-gpt>
  </div>

</template>

<style lang="scss" scoped>
.bg-white-really {
  background: #ffffff;
}
</style>
