<template>
  <div class="d-flex-center" :class="{ 'mr-5': !bionic, 'mr-3': bionic }">
    <div class="d-flex-center cursor-pointer record-button"
         :class="{ 'recording': recording}"
         @click="toggleRecording()">
      <fluency-icon type="record"
                    size="lg"
                    class="record-icon"
                    :class="{ 'text-danger': recording}" /><b>REC</b>
    </div>
  </div>
</template>

<script>
export default {
  name: 'userRecording',
  props: {
    bionic: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'success'
    }
  },
  data () {
    return {
      recordMode: false
    }
  },
  methods: {
    async toggleRecording () {
      const newVal = !this.recordMode
      this.recordMode = !newVal

      const resp = await this.$res.set.recordMode(newVal)

      if (resp) {
        this.$store.commit('user', { ...this.user, recordMode: newVal })
        this.$emit('toggle', newVal)
        return
      }

      // if response was unsuccessful, reset recordMode
      this.recordMode = !newVal
    }
  },
  watch: {
    'user.recordMode': {
      immediate: true,
      handler (val) {
        this.recordMode = val
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    recording () {
      return this.user?.recordMode
    }
  }
}
</script>

<style scoped lang="scss">
  .record-button:hover .record-icon {
    color: $danger;
  }
  .recording .record-icon {
    animation-duration: 666ms;
    animation-name: fade-in;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
</style>
