<template>
  <t-modal
    v-bind="modalProps"
    :id="confirmAlert.confirmAlertId"
    style="foo: bar;"
    v-model="visible"
    @hide="modalHide"
    @ok="modalOk">
    <p v-html="text"></p>
    <template v-if="dontAskMeAgain">
      <div class="mt-4">
        <b-form-checkbox class="d-inline-block"
                         :checked="dontAskVal"
                         @change="dontAskVal = !dontAskVal">{{ dontAskText }}</b-form-checkbox>

      </div>
    </template>

    <template v-if="buttons" #modal-footer>
      <b-button v-bind="button" v-for="button of buttons" :key="button.text"
                @click="modalOk(button.value)">
        {{button.text}}
      </b-button>
    </template>
  </t-modal>
</template>

<script setup>
import { ref, computed } from 'vue'
import useConfirmAlert from 'core-ui/composables/useConfirmAlert'

const confirmAlert = useConfirmAlert()

const text = computed(() => confirmAlert.globalOptions.value.text)
const dontAskMeAgain = computed(() => confirmAlert.globalOptions.value.dontAskMeAgain)
const buttons = computed(() => confirmAlert.globalOptions.value.buttons)
const isAlert = computed(() => confirmAlert.globalOptions.value.isAlert)
const modalProps = computed(() => {
  const { text, dontAskMeAgain, buttons, ...otherProps } = confirmAlert.globalOptions.value
  return otherProps
})

const modalHide = () => {
  confirmAlert.asyncResolve.value(false)
  setModalVisibility(false)
}
const modalOk = () => {
  let returnVal = true
  if (dontAskMeAgain.value) {
    returnVal = { value: true, dontAskAgain: dontAskVal.value }
  }
  setModalVisibility(false)
  confirmAlert.asyncResolve.value(returnVal)
}
const setModalVisibility = (val) => {
  visible.value = false // need to set the v-model'd value to false in case a second modal needs to be shown
}

const visible = confirmAlert.modalVisible
const dontAskVal = ref(false)
const dontAskText = isAlert.value ? 'Don\'t show again' : 'Don\'t show again'

</script>

<style lang='scss' scoped>
</style>
