<template>
  <div :class="classes.container" class="user-select-none">
    <div :class="[classes.nav, {'is-local-mode': runningLocal, 'is-record-mode': recording }]">
      <div class="d-flex flex-column">
        <!-- ------------------------------------------------------------------------------------------------------- -->
        <!-- TOP SECTION ------------------------------------------------------------------------------------------- -->
        <!-- ------------------------------------------------------------------------------------------------------- -->
        <div :class="classes.navTop">
          <!-- LOGO -->
          <div class="d-flex-center-center">
            <div ref="logoNavTarget"
                 class="brand-logo position-relative"
                 :class="classes.button"
                 @mouseenter="showTooltipOnLogo ? showNavWithDelay($event, navIds.logo) : null"
                 @mouseleave="showTooltipOnLogo ? handleNavMouseLeave(navIds.logo) : null"
                 @click.stop="showTooltipOnLogo ? showNavWithDelay($event, navIds.logo, 0) : null">
              <nuxt-link to="/" class="w-100" :data-qa="`${qa.primary}-home`">
                <img :src="logo.path" alt="logo" />
                <span v-if="internalShowNeedsRefresh" class="red-dot"></span>
              </nuxt-link>
            </div>
          </div>
          <div class="top-section-spacer brand-to-search"></div>
          <p-popover ref="logoNavPopoverRef"
                     @show="doReposition($refs.logoNavPopoverRef, { top: '3px' })"
                     @mouseenter="showNavWithDelay($event, navIds.logo, 0)"
                     @mouseleave="handleNavMouseLeave(navIds.logo)"
                     class="bionic-nav-popover"
                     :dt="bionicNavPopoverDesignTokens">
            <div :class="classes.menuContainer" class="pt-3">
              <ul :class="classes.ul">
                <li class="m-2">
                  <b-btn :variant="variant.menuItem"
                         @click="trackClickActionInPanel({ panelName: tracking.logo, action: 'reloadPlatform' }); reloadPlatform()">
                    <fluency-icon type="reload" class="mr-2" /> Reload Platform
                  </b-btn>
                </li>
              </ul>
            </div>
          </p-popover>

          <!-- SEARCH -->
          <div>
            <t-button-inline class="search-icon"
                             :class="classes.button"
                             v-p-tooltip="{ value: 'Jump to a Page\n(cmd + shift + j)', escape: false, showDelay: 250 }"
                             :data-qa="`${qa.primary}-search`"
                             @click.prevent="openSearch()">
              <div :class="classes.link">
                <fluency-icon type="search" :class="classes.linkIcon" />
              </div>
            </t-button-inline>
          </div>
          <div class="top-section-spacer search-to-links"></div>

          <!-- MANAGE, AUTOMATE, INSIGHTS -------------------------------------------------------------------------- -->
          <template v-for="(navItem, navKey) in navTreeTopLookup"
                    :key="navKey">
            <div :class="[classes.button]"
                 :data-qa="`${qa.primary}-${navItem.value}`"
                 tabindex="0"
                 :ref="`${navKey}NavTarget`"
                 @mouseenter="showNavWithDelay($event, navIds[navKey])"
                 @mouseleave="handleNavMouseLeave(navIds[navKey])"
                 @click.stop="showNavWithDelay($event, navIds[navKey], 0)">
              <div :class="[classes.link]">
                <fluency-icon :type="navItem.icon"
                              :class="[classes.linkIcon, { 'selected': navItem.isActive }]"/>
              </div>
              <div :class="[classes.buttonLabel, { 'selected': navItem.isActive }]">{{ navItem.label }}</div>
            </div>
            <p-popover :ref="`${navKey}NavPopoverRef`"
                       @mouseenter="showNavWithDelay($event, navIds[navKey], 0)"
                       @mouseleave="handleNavMouseLeave(navIds[navKey])"
                       @show="doReposition($refs[`${navKey}NavPopoverRef`][0], navItem.position)"
                       class="bionic-nav-popover"
                       :dt="bionicNavPopoverDesignTokens">
              <div :class="classes.menuContainer">

                <ul :class="classes.ul">
                  <li v-for="(subnav, subnavKey) in navItem.subNav"
                      :key="subnavKey">
                    <template v-if="subnav.items && subnav.items.length > 0">
                      <h6 :class="classes.menuHeading">
                        {{subnav.label}}
                        <span class="flex-grow-1 border-bottom ml-2"></span>
                      </h6>
                      <ul :class="classes.subUl">
                        <li v-for="(subnavItem, subnavItemKey) in subnav.items"
                            :key="`${subnavKey}-${subnavItemKey}`"
                            @click="trackNavLinkClickWithPanelName({ panelName: tracking[navKey], navTo: $filters.convertToCamelCase(subnavItem.text) })">
                          <nuxt-link :to="subnavItem.link"
                                     :class="[classes.menuItem, { 'selected': isLinkActive(subnavItem.link) }]"
                                     :data-qa="`${qa.popover}-${subnavItem.qa}`">
                            {{subnavItem.text}}
                          </nuxt-link>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </div>
            </p-popover>
            <div class="top-section-spacer"></div>
          </template>

        </div>

        <!-- ------------------------------------------------------------------------------------------------------- -->
        <!-- BOTTOM SECTION ---------------------------------------------------------------------------------------- -->
        <!-- ------------------------------------------------------------------------------------------------------- -->
        <div :class="classes.navBottom">

          <div>
            <t-button-inline class="muse-icon"
                             :class="classes.button"
                             v-p-tooltip="{ value: 'Open Muse AI\n(cmd + shift + m)', escape: false, showDelay: 250 }"
                             :data-qa="`${qa.primary}-muse`"
                             v-shortkey="['meta', 'shift', 'm']"
                             @shortkey="museOpen = !museOpen"
                             @click.prevent="museOpen = !museOpen">
              <div :class="classes.link">
                <fluency-icon type="muse" :class="classes.linkIcon" />
              </div>
            </t-button-inline>
          </div>

          <div class="bottom-section-spacer"></div>

          <template v-if="user && user.capabilities && user.capabilities.RecordMode && recording">
            <div class="d-flex-center-center my-4">
              <user-recording :bionic="true" @toggle="trackClickActionInPanel({ panelName: 'recordMode', action: 'recordMode' })" />
            </div>
          </template>

          <!-- CUSTOMER SWITCHER (INTERNAL ONLY) -->
          <div :data-qa="`${qa.primary}-customerSwitcher`">
            <user-customer-selector v-if="user && user.capabilities && user.capabilities.CustomerSwitcher"
                                    :bionic="true"
                                    :popover="popover"
                                    @change="trackClickActionInPanel({ panelName: 'customerSwitcher', action: $event })"
            />
          </div>
          <div class="bottom-section-spacer"></div>

          <!-- SETTINGS -->
          <div tabindex="0"
               :data-qa="`${qa.primary}-settings`"
               :class="[classes.button]"
               ref="settingsNavTarget"
               @mouseenter="showNavWithDelay($event, navIds.settings)"
               @mouseleave="handleNavMouseLeave(navIds.settings)"
               @click.stop="showNavWithDelay($event, navIds.settings, 0)">
            <div :class="classes.link">
              <fluency-icon type="settings" :class="classes.linkIcon" />
            </div>
          </div>
          <div class="bottom-section-spacer"></div>

          <!-- HELP -->
          <div :data-qa="`${qa.primary}-help`"
               tabindex="0"
               :class="[classes.button]"
               ref="supportNavTarget"
               @mouseenter="showNavWithDelay($event, navIds.help)"
               @mouseleave="handleNavMouseLeave(navIds.help)"
               @click.stop="showNavWithDelay($event, navIds.help, 0)">
            <div :class="classes.link">
              <fluency-icon type="help" :class="classes.linkIcon" />
            </div>
          </div>
          <div class="bottom-section-spacer"></div>

          <!-- USER -->
          <div tabindex="0"
               ref="userNavTarget"
               :data-qa="`${qa.primary}-user`"
               :class="[classes.button]"
               @mouseenter="showNavWithDelay($event, navIds.user)"
               @mouseleave="handleNavMouseLeave(navIds.user)"
               @click.stop="showNavWithDelay($event, navIds.user, 0)">
            <div :class="classes.link">
              <fluency-icon type="user" :class="classes.linkIcon" />
            </div>
          </div>

          <!-- ----------------------------------------------------------------------------------------------------- -->
          <!-- POPOVERS -------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------------------------------------------------------------------- -->
          <div>
            <!-- --------------------------------------------------------------------------------------------------- -->
            <!-- SETTINGS ------------------------------------------------------------------------------------------ -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <p-popover ref="settingsNavPopoverRef"
                       @show="doReposition($refs.settingsNavPopoverRef)"
                       @mouseenter="showNavWithDelay($event, navIds.settings, 0)"
                       @mouseleave="handleNavMouseLeave(navIds.settings)"
                       class="bionic-nav-popover"
                       :dt="bionicNavPopoverDesignTokens">
              <div :class="classes.menuContainer">
                <ul :class="classes.ul">
                  <li v-if="navTreeSettings">
                    <ul v-for="(navItem, navItemKey) in navTreeSettings"
                        :key="navItemKey"
                        :class="classes.subUl">
                      <template v-for="(subNav, subNavKey) in navItem.subNav"
                                :key="subNavKey">
                        <li v-if="subNav.items && subNav.items.length > 0">
                          <h4 :class="classes.menuHeading">
                            {{subNav.label}}<span class="flex-grow-1 border-bottom ml-3"></span>
                          </h4>

                          <ul :class="classes.subUl">
                              <li v-for="(linkItem, linkItemKey) in subNav.items"
                                  :key="linkItemKey"
                                  class="m-2"
                                  @click="trackNavLinkClickWithPanelName({ panelName: tracking.settings, navTo: $filters.convertToCamelCase(linkItem.text) })">
                                <nuxt-link :to="linkItem.link"
                                           :data-qa="`${qa.popover}-${linkItem.qa}`"
                                           :class="classes.menuItem">
                                  <fluency-icon v-if="linkItem.icon"
                                                :type="linkItem.icon"
                                                class="mr-2" />
                                  {{linkItem.text}}
                                </nuxt-link>
                              </li>
                          </ul>
                        </li>
                      </template>
                    </ul>
                  </li>
                </ul>
              </div>
            </p-popover>

            <!-- --------------------------------------------------------------------------------------------------- -->
            <!-- SUPPORT ------------------------------------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <p-popover ref="supportNavPopoverRef"
                       @show="doReposition($refs.supportNavPopoverRef)"
                       @mouseenter="showNavWithDelay($event, navIds.help, 0)"
                       @mouseleave="handleNavMouseLeave(navIds.help)"
                       class="bionic-nav-popover"
                       :dt="bionicNavPopoverDesignTokens">
              <div :class="classes.menuContainer">
                <ul :class="classes.ul">
                  <li>
                    <h4 :class="classes.menuHeading">
                      Fluency Help
                      <span class="flex-grow-1 border-bottom ml-3"></span>
                    </h4>

                    <ul :class="classes.subUl">
                      <li class="m-2"
                          @click="trackNavLinkClickWithPanelName({ panelName: tracking.support, navTo: 'helpCenter' })">
                        <a href="http://help.fluency.inc/"
                           target="_blank"
                           :data-qa="`${qa.popover}-helpCenter`"
                           :class="classes.menuItem">
                          <fluency-icon type="linkOut" class="mr-2" /> Help Center
                        </a>
                      </li>
                      <li v-if="whatFixEnabled"
                          class="m-2">
                        <fluency-support :inline="true"
                                         :hide-support-modal="true"
                                         :data-qa="qa.popover"
                                         @itemClick="trackClickActionInPanel({ panelName: tracking.support, action: $event.item })" />

                        <a href="#"
                           :class="classes.menuItem"
                           @click.prevent="loadFluencySupportModal()">
                          <fluency-icon type="help" class="mr-2" /> Request Support
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </p-popover>

            <fluency-support :inline="true"
                             :hidden="true"
                             :data-qa="qa.popover"
                             @itemClick="trackClickActionInPanel({ panelName: tracking.support, action: $event.item })" />

            <!-- --------------------------------------------------------------------------------------------------- -->
            <!-- USER ---------------------------------------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <p-popover ref="userNavPopoverRef"
                       @show="doReposition($refs.userNavPopoverRef)"
                       @mouseenter="showNavWithDelay($event, navIds.user, 0)"
                       @mouseleave="handleNavMouseLeave(navIds.user)"
                       class="bionic-nav-popover user-nav"
                      :dt="bionicNavPopoverDesignTokens">
              <div class="d-flex-center-end">
                <template v-if="user && user.capabilities && user.capabilities.RecordMode">
                  <user-recording :bionic="true"  @toggle="trackClickActionInPanel({ panelName: tracking.user, action: 'recordMode' })" />
                </template>
              </div>
              <div :class="classes.menuContainer">
                <ul :class="classes.ul">
                  <li class="my-2">
                    <h4 :class="classes.menuHeading">
                      {{user.given_name}} {{user.family_name}} ({{user.userName}})
                      <em v-if="readOnly" class="text-muted">(read-only)</em>
                      <span class="flex-grow-1"><!-- SPACER FOR ALIGNMENT --></span>
                    </h4>
                  </li>
                  <li class="my-2">{{user.userName}}</li>
                </ul>

                <ul :class="classes.ul">
                  <li v-if="oktaUserId" class="my-3 mx-0 pb-4">
                    <t-button-inline @click="mfaEnrollClick">Manage MFA Login for Backpack</t-button-inline>
                  </li>
                </ul>

                <ul :class="classes.ul">
                  <li>
                    <h4 :class="classes.menuHeading">
                      <span class="flex-grow-1 border-bottom ml-3"></span>
                    </h4>
                  </li>
                  <li class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-setDefaultHomepage`"
                           class="d-flex-start"
                           @click="setDefaultLandingPageToCurrent">
                      <fluency-icon :type="isDefaultLandingPage ? 'star' : 'emptyStar'"
                                    class="mr-2"
                                    :class="{'active':isDefaultLandingPage, 'landing-page-control':true}" />
                      <span class="ml-2">
                        {{getDefaultLandingPageTooltipText()}}
                      </span>
                    </b-btn>
                  </li>
                  <li class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-visibilityPreferences`"
                           @click.prevent="userPersonalModal()">
                      <fluency-icon type="preview" class="mr-2" />
                      Visibility Preferences
                    </b-btn>
                  </li>
                  <li class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-reloadPlatform`"
                           @click="trackClickActionInPanel({ panelName: tracking.user, action: 'reloadPlatform' }); reloadPlatform()">
                      <fluency-icon type="reload" class="mr-2" /> Reload Platform
                    </b-btn>
                  </li>
                  <li class="m-2">
                    <nuxt-link to="/welcome/"
                               :data-qa="`${qa.popover}-welcomePage`"
                               :class="classes.menuItem">
                      <fluency-icon type="trailguide"></fluency-icon>
                      Get Started with Fluency
                    </nuxt-link>
                  </li>
                  <li v-if="isInternalUser" class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-switchToExternalUser`"
                           @click="switchToExternalUser()">
                      <fluency-icon type="logout" class="mr-2" />
                      Move to External User
                    </b-btn>
                  </li>
                  <li class="m-2"
                      :data-qa="`${qa.popover}-userModeSwitcher`"
                      @click.stop>
                    <user-mode-switcher @toggle="trackClickActionInPanel({ panelName: tracking.user, action: 'userModeSwitch' })" />
                  </li>
                  <li class="m-2"
                      :data-qa="`${qa.popover}-performanceBounds`"
                      @click.stop>
                    <guardrail-mode-switcher @toggle="trackClickActionInPanel({ panelName: tracking.user, action: 'performanceBoundsSwitch' })" />
                  </li>
                  <li class="m-2"
                      :data-qa="`${qa.popover}-allowEmailsFromFluency`"
                      @click.stop>
                    <user-can-email-switcher @toggle="trackClickActionInPanel({ panelName: tracking.user, action: 'allowEmailSwitch' })" />
                  </li>
                </ul>

                <ul :class="classes.ul">
                  <li :data-qa="`${qa.popover}-accountTwoFactor`"
                      @click.stop>
                    <account-two-factor />
                  </li>
                </ul>

                <ul :class="classes.ul" class="py-2 mt-4 border-top">
                  <li>
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-logout`"
                           @click.prevent="logout()">
                      <fluency-icon type="logout" class="mr-2" /> Sign Out
                    </b-btn>
                  </li>
                </ul>
              </div>
            </p-popover>
          </div>
        </div>
      </div>
    </div>

    <url-modal></url-modal>
    <jumper2 />
    <mfa-enroll v-model="mfaEnrollOpen" />
    <user-persona-configuration :value="showModalUserPersonaSettings"
                                @hide-modal="userPersonalModal(false)" />
  </div>
</template>

<script>
import UserCustomerSelector from '@/components/common/userCustomerSelector.vue'
import FluencySupport from '@/components/common/fluencySupport.vue'
import AccountTwoFactor from '@/components/common/accountTwoFactor.vue'
import { useServiceWorker } from '@/composables/useServiceWorker.js'
import { useBionicNavUtils } from '@/composables/useBionicNavUtils.js'
import { useMusePanel } from 'core-ui/composables/useMusePanel.js'
import MfaEnroll from '@/components/common/mfaEnroll.vue'
import UserModeSwitcher from '@/components/common/userModeSwitcher.vue'
import UserCanEmailSwitcher from '@/components/common/userCanEmailSwitcher.vue'
import UserPersonaConfiguration from '@/components/common/userPersonaConfiguration.vue'
import GuardrailModeSwitcher from '@/components/common/guardrailModeSwitcher.vue'
import UserRecording from '~/components/common/userRecording.vue'
import UrlModal from '@/components/common/urlModal.vue'
import Jumper2 from '~/components/common/jumper2.vue'

export default {
  name: 'bionicNavigation',
  props: {},
  components: {
    UserCustomerSelector,
    FluencySupport,
    AccountTwoFactor,
    MfaEnroll,
    UserModeSwitcher,
    UserCanEmailSwitcher,
    UserPersonaConfiguration,
    GuardrailModeSwitcher,
    UserRecording,
    UrlModal,
    Jumper2
  },
  setup () {
    const { isWaiting, deleteRuntimeCache } = useServiceWorker()
    const { repositionRight, addNavPopover, showNavWithDelay, hideNavWithDelay, hideAllNavs, bionicNavPopoverDesignTokens } = useBionicNavUtils()
    const { museOpen } = useMusePanel()

    return {
      isWaiting,
      deleteRuntimeCache,
      repositionRight,
      addNavPopover,
      showNavWithDelay,
      hideNavWithDelay,
      hideAllNavs,
      bionicNavPopoverDesignTokens,
      museOpen
    }
  },
  created () {
    if (this.$store.getters.isLocalDev) {
      this.runningLocal = true
    }
  },
  data () {
    return {
      debug: false,
      runningLocal: false,
      classes: {
        container: 'bionic-nav-container',
        nav: 'bionic-nav',
        navTop: 'bionic-nav-top',
        navBottom: 'bionic-nav-bottom',
        button: 'bionic-nav-button d-flex-center-center flex-column p-0 no-focus position-relative',
        buttonLabel: 'bionic-nav-button-label position-absolute bottom-0',
        link: 'bionic-nav-link d-flex-center-center',
        linkIcon: 'bionic-nav-link-icon',
        menuContainer: 'bionic-nav-menu-container',
        menuHeading: 'bionic-nav-menu-heading',
        ul: 'bionic-nav-menu list-unstyled mb-0',
        subUl: 'bionic-nav-sub-menu list-unstyled py-2',
        menuItem: 'btn btn-bionic-nav-menu-item'
      },
      variant: {
        menuItem: 'bionic-nav-menu-item'
      },
      qa: {
        primary: 'navigation-primaryLink',
        popover: 'navigation-popoverLink'
      },
      tracking: {
        category: 'navigation',
        topLevel: 'bionic-nav-top-level',
        logo: 'logo',
        manage: 'manage',
        automate: 'automate',
        insights: 'insights',
        settings: 'settings',
        support: 'support',
        user: 'user'
      },
      mfaEnrollOpen: false,
      showModalUserPersonaSettings: false,
      navIds: {
        logo: 'logoBionicNav',
        manage: 'manageBionicNav', // manage, automate, insights keys match up with topLevelNav keys
        automate: 'automateBionicNav',
        insights: 'insightsBionicNav',
        help: 'helpBionicNav',
        settings: 'settingsBionicNav',
        user: 'userBionicNav'
      },
      topLevelNav: {
        manage: 'manage',
        automate: 'automate',
        insights: 'insights',
        settings: 'settings'
      }
    }
  },
  computed: {
    debugMode () {
      return this.debug && this.isInternalUser
    },
    logo () {
      return this.$store.getters.whiteLabel.bionicLogo
    },
    navigationForUser () {
      return this.$store.getters?.navigationForUser?.navigation || []
    },
    navTree () {
      const tree = []

      tree.push({
        value: this.topLevelNav.manage,
        label: 'Manage',
        icon: 'globe',
        position: {
          top: '104px'
        },
        section: 'top',
        isActive: !!this.activeSections.find(sec => sec.top === 'manage'),
        subNav: [
          {
            value: 'portfolio',
            label: 'Portfolio',
            items: this.subNavItems?.manage?.portfolio || []
          },
          {
            value: 'bulkManage',
            label: 'Bulk',
            items: this.subNavItems?.manage?.bulkManage || []
          },
          {
            value: 'tools',
            label: 'Manage Tools',
            items: this.subNavItems?.manage?.tools || []
          }
        ]
      })
      tree.push({
        value: this.topLevelNav.automate,
        label: 'Automate',
        icon: 'rocket',
        position: {
          top: '181px'
        },
        section: 'top',
        isActive: !!this.activeSections.find(sec => sec.top === 'automate'),
        subNav: [
          {
            value: 'blueprints',
            label: 'Blueprints',
            items: this.subNavItems?.automate?.blueprints || []
          },
          {
            value: 'launch',
            label: 'Launch',
            items: this.subNavItems?.automate?.launch || []
          },
          {
            value: 'blueprintData',
            label: 'Blueprint Data',
            items: this.subNavItems?.automate?.blueprintData || []
          },
          {
            value: 'automationTools',
            label: 'Automation Tools',
            items: this.subNavItems?.automate?.automationTools || []
          }
        ]
      })
      tree.push({
        value: this.topLevelNav.insights,
        label: 'Insights',
        icon: 'graph',
        position: {
          top: '260px'
        },
        section: 'top',
        isActive: !!this.activeSections.find(sec => sec.top === 'insights'),
        subNav: [
          {
            value: 'reportingTools',
            label: 'Reporting Tools',
            items: this.subNavItems?.insights?.reportingTools || []
          },
          {
            value: 'monitor',
            label: 'Monitor',
            items: this.subNavItems?.insights?.monitor || []
          }
        ]
      })
      tree.push({
        value: this.topLevelNav.settings,
        label: 'Settings',
        icon: 'settings',
        section: 'settings',
        subNav: [
          {
            value: 'globalSettings',
            label: 'Global Settings',
            items: this.subNavItems?.settings?.globalSettings || []
          },
          {
            value: 'internalTools',
            label: 'Internal Tools',
            items: this.subNavItems?.settings?.internalTools || []
          }
        ]
      })

      return tree
    },
    navTreeTopLookup () {
      return this.navTree.filter(n => n.section === 'top').toLookup(nav => nav.value)
    },
    navTreeSettings () {
      return this.navTree.filter(n => n.section === 'settings')
    },
    subNavItems () {
      const o = {}

      this.navigationForUser.forEach(navItem => {
        if (Array.isArray(navItem.placements)) {
          navItem.placements.forEach(placement => {
            if (!o.hasOwnProperty(placement.top)) {
              o[placement.top] = {}
            }
            if (!o[placement.top].hasOwnProperty(placement.sub)) {
              o[placement.top][placement.sub] = []
            }

            o[placement.top][placement.sub].push(navItem)
          })
        }
      })

      return o
    },
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    isInternalUser () {
      return this.user?.internalUser
    },
    readOnly () {
      return (this.user.roles || []).includes('read_only')
    },
    recording () {
      return this.user?.recordMode
    },
    oktaUserId () {
      return this.$store.getters.oktaUserId
    },
    capabilities () {
      return this?.user?.capabilities
    },
    channels () {
      return this.$store.getters.advertisingChannels
    },
    activeSections () {
      const fullPathname = this.$route.fullPath

      const matches = this.navigationForUser.find(navItem => fullPathname === navItem.link)

      return matches?.placements || []
    },
    activeLinks () {
      const fullPathname = this.$route.fullPath
      const justPath = this.$route.path

      const matches = this.navigationForUser.filter(navItem => {
        const specialCase = ['/manage/', '/alerts/'].some(pattern => {
          if (pattern === '/alerts/') {
            // weird url pattern for Notifications page using URL params
            return justPath === pattern && navItem.link === justPath && navItem.link.startsWith(pattern)
          }

          return fullPathname.startsWith(pattern) && navItem.link.startsWith(pattern)
        })

        return specialCase || fullPathname === navItem.link
      })

      return matches || []
    },
    whatFixEnabled () {
      return this.user?.capabilities?.WhatFix
    },
    showTooltipOnLogo () {
      return this.internalShowNeedsRefresh
    },
    internalShowNeedsRefresh () {
      return (this.isWaiting && this.isInternalUser) || this.debugMode
    },
    userAndChannels () {
      return {
        user: this.user,
        channels: this.channels
      }
    },
    defaultHomePage () {
      return this.user.defaultHomePage || '/welcome/'
    },
    isDefaultLandingPage () {
      const homePageRoute = this.$router.resolve(this.defaultHomePage)
      if (homePageRoute?.name?.length > 0) {
        return this.$route.fullPath.startsWith(this.defaultHomePage)
      }
      return false
    }
  },
  mounted () {
    this.addNavPopover({
      navId: this.navIds.logo,
      popoverRef: this.$refs.logoNavPopoverRef,
      targetEl: this.$refs.logoNavTarget
    })
    this.addNavPopover({
      navId: this.navIds.manage,
      popoverRef: this.$refs.manageNavPopoverRef[0], // we loop through these in a v-for, so vue turns the refs into arrays
      targetEl: this.$refs.manageNavTarget[0]
    })
    this.addNavPopover({
      navId: this.navIds.automate,
      popoverRef: this.$refs.automateNavPopoverRef[0], // we loop through these in a v-for, so vue turns the refs into arrays
      targetEl: this.$refs.automateNavTarget[0]
    })
    this.addNavPopover({
      navId: this.navIds.insights,
      popoverRef: this.$refs.insightsNavPopoverRef[0], // we loop through these in a v-for, so vue turns the refs into arrays
      targetEl: this.$refs.insightsNavTarget[0]
    })
    this.addNavPopover({
      navId: this.navIds.settings,
      popoverRef: this.$refs.settingsNavPopoverRef,
      targetEl: this.$refs.settingsNavTarget
    })
    this.addNavPopover({
      navId: this.navIds.help,
      popoverRef: this.$refs.supportNavPopoverRef,
      targetEl: this.$refs.supportNavTarget
    })
    this.addNavPopover({
      navId: this.navIds.user,
      popoverRef: this.$refs.userNavPopoverRef,
      targetEl: this.$refs.userNavTarget
    })
  },
  watch: {
    '$route.fullPath': {
      handler () {
        this.hideAllNavs()
      }
    },
    userAndChannels: {
      immediate: true,
      deep: true,
      handler () {
        this.$store.dispatch('trimNavForUser')
      }
    }
  },
  methods: {
    loadFluencySupportModal () {
      this.$eventBus.$emit('bionicOpenRequestSupportModal')
    },
    handleNavMouseLeave (navId) {
      if (this.debugMode) {
        return
      }
      this.hideNavWithDelay(navId)
    },
    toggleBionicLayout () {
      this.trackClickActionInPanel({ panelName: this.tracking.user, action: 'toggleBionicLayout' })
      this.$store.dispatch('toggleForkedUiElement', { val: null, key: 'nav' })
    },
    // topLevelLinkClick (navItem) {
    // TODO: re-enable later
    // let primaryLink = null
    //
    // for (const subNav of navItem?.subNav) {
    //   if (!primaryLink) {
    //     for (const item of subNav?.items) {
    //       if (!primaryLink) {
    //         for (const placement of item?.placements) {
    //           if (!primaryLink) {
    //             if (placement?.primary === true) {
    //               primaryLink = item
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    //
    // if (primaryLink && primaryLink?.link) {
    //   const { path, query } = this.dissectUrl(primaryLink.link)
    //
    //   this.$router.push({ path, query: { ...query } })
    // }
    // },
    // dissectUrl (link) {
    //   const path = link?.split('?')?.[0]
    //   const query = link?.split('?')?.[1] || ''
    //   const o = new URLSearchParams(query)
    //
    //   return { path, query: this.paramsToObject(o) }
    // },
    isLinkActive (item) {
      return !!this.activeLinks.find(link => link.link === item)
    },
    async logout () {
      this.trackClickActionInPanel({ panelName: this.tracking.user, action: 'logout' })

      if (this.$authn.isAuthenticated()) {
        await this.$authn.logout()
      }
    },
    async reloadPlatform () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          capabilities: response.capabilities
        }

        await Promise.all([
          this.$store.commit('user', newUser)
        ])
      }

      ['verticals', 'skeletons', 'userSavedSegments', 'savedSegmentFields', 'enumerations', 'reportDefinitions', 'partners', 'advertisingChannels'].forEach(cacheKey => {
        localStorage.removeItem(cacheKey)
      })

      await this.deleteRuntimeCache()

      window.location.reload(true) // this non-standard param is for Firefox
    },
    mfaEnrollClick () {
      this.trackClickActionInPanel({ panelName: this.tracking.user, action: 'mfaEnroll' })
      this.mfaEnrollOpen = true
    },
    switchToExternalUser () {
      const newUser = {
        ...this.user,
        internalUser: false
      }

      this.$store.commit('user', newUser)

      this.trackClickActionInPanel({ panelName: this.tracking.user, action: 'switchUserToExternal' })
    },
    userPersonalModal (val = true) {
      this.trackClickActionInPanel({ panelName: this.tracking.user, action: 'visibilityPreferences' })
      this.showModalUserPersonaSettings = val
    },
    openSearch () {
      this.trackClickActionInPanel({ panelName: 'search', action: 'click' })
      this.$eventBus.$emit('openAccountJump')
    },
    getDefaultLandingPageTooltipText () {
      if (this.isDefaultLandingPage) {
        // return 'This is your home page. To change it, navigate to your desired home page and click this button.'
        return 'This is your default page'
      } else {
        // return 'Click to set this page as your default home page'
        return 'Set as default page'
      }
    },
    setDefaultLandingPageToCurrent () {
      this.$res.set.userHomePage(this.$route.path)
      this.$store.commit('userDefaultHomePage', this.$route.path)
      this.$toast(`Default Home Page set to ${this.$route.path}`, 'success')
      this.trackClickActionInPanel({ panelName: this.tracking.user, action: 'defaultHomePage' })
    },
    trackClickActionInPanel ({ panelName, action }) {
      this.trackEvent(this.tracking.topLevel, { name: panelName, action })
    },
    trackNavLinkClickWithPanelName ({ panelName, navTo }) {
      this.trackEvent(this.tracking.topLevel, { name: panelName, action: navTo })
    },
    trackEvent (name, meta) {
      // name, meta, category, element
      this.$track.event(name, meta, this.tracking.category)
    },
    doReposition (r, pos = { bottom: '16px' }) {
      this.repositionRight(r, pos)
    }
  }
}
</script>

<style scoped lang="scss">
.bionic-nav-container {
  display: flex;
  height: 100%;
}
.bionic-nav {
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-columns: 64px;
  text-align: center;
  background-color: $bionic-nav-bg;
  border-right: 1px solid $bionic-300;

  &.is-local-mode {
    background-color: #EEF4FF !important;
  }
  &.is-record-mode {
    background-color: lighten($fluency-red, 32) !important;
  }

  .red-dot {
    position: absolute;
    top: 2px;
    right: 18px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: $fluency-red;
    color: $fluency-red;
  }
}

.bionic-nav-button {
  width: 100%;
}

.bionic-nav-link {
  //width: 36px;
  //height: 36px;
  border-radius: 8px;
  color: $bionic-700;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  cursor: pointer;

  //&.active,
  &:hover {
    background-color: #E0EAFF;
    color: $bionic-700;
    transition: background-color 0.25s ease-in, color 0.25s ease-in;

    .bionic-nav-link-icon {
      color: #444CE7;
    }
  }
  .bionic-nav-link-icon {
    width: 24px;
    padding-top: 4px; // needed for centering
  }
}

.bionic-nav-top,
.bionic-nav-bottom {
  padding-top: 24px;
  padding-bottom: 24px;
}

.bionic-nav-top {
  flex-grow: 1 !important;

  .bionic-nav-button {
    text-decoration: none;
    padding-bottom: 18px !important;

    &.brand-logo {
      padding-bottom: 0 !important;
      min-height: 32px;
    }

    &.search-icon {
      padding-bottom: 0 !important;
    }
  }

  .bionic-nav-button-label {
    font-size: 11px;
    font-weight: 600;
    color: #5D6B98;
    &.selected {
      color: #6673FF;
    }
  }

  .bionic-nav-button:hover { //, .bionic-nav-button.active {
    .bionic-nav-button-label {
      color: #444CE7;
    }
  }

  .top-section-spacer {
    height: 24px;

    &.brand-to-search {
      height: 30px;
    }
    &.search-to-links {
      height: 36px;
    }
  }
}
.bionic-nav-bottom {
  .bottom-section-spacer {
    height: 24px;
  }
}
</style>

<style lang="scss">/* not scoped because we need to apply styles inside p-popover components */
.bionic-nav-popover {
  width: 324px;
  min-width: 300px;

  &:before {
    content: none !important;
  }

  &.user-nav {
    width: 360px;
  }

  .bionic-nav-menu-container {
    //min-width: 300px;
    padding: 16px 8px 0 8px;
  }
}

</style>
