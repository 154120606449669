<template>
  <div>
    <div class="d-flex-center my-4">
      <t-alert class="my-0 mr-4">Below you can select the suggestions that you like then click save to save them as draft and we will bring you to the account to edit them further.</t-alert>
      <fluency-save-button id="broadcastSaveButton"
                           class="white-space-no-wrap"
                           :variant="(dirtyTracker) ? 'warning' : 'primary'"
                           reverse-sync-check
                           :icon-type="!accountDataSaveInProgress ? 'save' : 'loop'"
                           :icon-class="accountDataSaveInProgress ? 'rotating' : ''"
                           :disabled="accountDataSaveInProgress || (selectedBroadcasts.length === 0)"
                           :tooltip-message="'Save Account'"
                           :text="(selectedBroadcasts.length > 0) ? `Save ${selectedBroadcasts.length} Selected` : 'Save'"
                           @click="saveDirtyData"/>
    </div>
    <broadcast :provided-account-broadcasts="broadcasts" @update="updateBroadcasts($event)" @selected="handleSelectedBroadcasts($event)"/>
  </div>
</template>

<script>
import Broadcast from '../../../apps/backpack/src/components/common/broadcast'
import FluencySaveButton from 'backpack/src/components/common/forms/_fluencySaveButton'

export default {
  name: 'broadcastSuggestions',
  components: { FluencySaveButton, Broadcast },
  props: {
    // Optional prop to override the default account broadcasts
    providedAccountBroadcasts: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      accountDataSaveInProgress: false,
      responseError: false,
      broadcasts: [],
      selectedBroadcasts: []
    }
  },
  watch: {
    providedAccountBroadcasts: {
      handler (val) {
        // Map through the broadcasts and modify them
        const modifiedBroadcasts = val.map(broadcast => {
          const { status, ...rest } = broadcast
          return {
            ...rest,
            dirty: true
          }
        })
        this.$setCompat(this, 'broadcasts', modifiedBroadcasts)
      },
      immediate: true
    }
  },
  computed: {
    dirtyTracker () {
      // console.log(typeof this.broadcasts)
      return this.broadcasts.some(b => b.dirty)
    }
  },
  methods: {
    handleSelectedBroadcasts (selectedBroadcasts) {
      // Do something with the selected broadcasts
      this.selectedBroadcasts = selectedBroadcasts
      // console.log(selectedBroadcasts)
    },
    async saveDirtyData () {
      this.accountDataSaveInProgress = true
      if (this.dirtyTracker && !this.responseError) {
        const response = await this.$store.dispatch('saveBroadcasts', (this.selectedBroadcasts.length > 0) ? this.selectedBroadcasts : this.broadcasts)
        if (!response) {
          this.responseError = true
        } else {
          // console.log('saved')
          this.goToAccountBroadcast()
        }
      }
      this.accountDataSaveInProgress = false
    },
    updateBroadcasts (val) {
      this.broadcasts = val
    },
    goToAccountBroadcast () {
      // /manage/account/?id=5681&fsEnv=canary&settingsItemId=5681&settingsItemType=account&form=broadcast&content=
      // After save is issued, navigate to the route
      this.$eventBus.$emit('closeMuseChatDrawer')

      if (this.broadcasts && this.broadcasts.length > 0) {
        this.$router.push({
          // path: '/manage/account/',
          query: {
            // id: this.broadcasts[0].accountPlanId,
            settingsItemId: this.broadcasts[0].accountPlanId,
            settingsItemType: 'account',
            form: 'broadcast'
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
