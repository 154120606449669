import { ref } from 'vue'

const slashCommands = ref(null)
export const useSlashCommands = () => {
  const { $res } = useNuxtApp()
  const fetchSlashCommands = async () => {
    const res = await $res.ai.slash()
    if (res) {
      slashCommands.value = res
    }
  }
  if (!slashCommands.value) {
    fetchSlashCommands()
  }
  return { slashCommands }
}
