<script setup>
import { useSlashCommands } from '~/composables/useSlashCommands'
import { ref, watch, nextTick, onMounted, onBeforeUnmount, computed } from 'vue'
import _debounce from 'lodash.debounce'
import FluencyIcon from 'core-ui/components/icons/fluencyIcon.vue'
const { slashCommands } = useSlashCommands()
const { $filters } = useNuxtApp()
const props = defineProps({
  prompt: String,
  target: String
})
const emit = defineEmits(['input'])
const wait = 300
const popoverRef = ref(null)
const groupedSlashCommands = ref({})
const targetNode = computed(() => document.getElementById(props.target))
const position = async () => {
  if (popoverRef.value?.visible) {
    await nextTick()
    popoverRef.value.alignOverlay()
  }
}

const filterSlashCommands = _debounce(() => {
  if (props.prompt.charAt(0) !== '/') {
    groupedSlashCommands.value = {}
  } else {
    groupedSlashCommands.value = slashCommands.value
      ?.filter(item => item.command.indexOf(props.prompt?.replace('/', '')) === 0)
      .sort((a, b) => a.category === b.category ? 0 : a.category > b.category ? 1 : -1)
      .map((item, index) => ({ ...item, tabindex: 501 + index }))
      .groupBy('category')
  }
  if (popoverRef.value?.visible && Object.keys(groupedSlashCommands.value).length === 0) {
    const ev = new Event('togglePopover')
    targetNode.value.dispatchEvent(ev)
  } else if (!popoverRef.value?.visible && Object.keys(groupedSlashCommands.value).length > 0) {
    const ev = new Event('togglePopover')
    targetNode.value.dispatchEvent(ev)
  }
  position()
}, wait)

watch(
  () => props.prompt,
  () => {
    filterSlashCommands()
  },
  {
    immediate: true
  }
)

const toggle = (event) => {
  if (popoverRef.value?.visible) {
    popoverRef.value.hide(event)
  } else {
    popoverRef.value.show(event)
  }
}

onMounted(() => {
  targetNode.value.addEventListener('togglePopover', toggle)
})

onBeforeUnmount(() => {
  targetNode.value.removeEventListener('togglePopover', toggle)
})
</script>

<template>
  <p-popover ref="popoverRef" id="muse-slash-command-popover" pt:content:style="max-height: 50vh;">
    <div v-for="(list, key) in groupedSlashCommands" :key="key" class="mb-3">
      <div class="d-flex-center w-100 mb-3">
        <span class="font-weight-bold pr-4">{{$filters.convertFromCamelCase(key)}}</span>
        <div class="border-bottom flex-grow-1"></div>
      </div>
      <div v-for="item in list"
           :key="item.command"
           :tabindex="item.tabindex"
           class="d-flex align-items-start p-3 rounded slash-command cursor-pointer"
           @keyup.enter="emit('input', item.command)"
           @click="emit('input', item.command)">
        <fluency-icon type="slashCommandIcon" size="lg" class="mr-3 mt-2"></fluency-icon>
        <div>
          <div>{{$filters.convertFromCamelCase(item.command)}}</div>
          <small class="text-muted d-block" style="max-width: 400px;">{{item.description}}</small>
        </div>
      </div>
    </div>
  </p-popover>
</template>

<style lang="scss">
.slash-command {
  &:hover,
  &:focus {
    background: var(--p-sky-50);
    border: none;
    box-shadow: none;
    outline: none;
  }
}
#muse-slash-command-popover {
  &:before,
  &:after {
    display: none;
  }
}
</style>
