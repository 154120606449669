<script setup>
import { computed, watch, ref, onBeforeUnmount } from 'vue'
import { settingsNav } from '../../assets/js/constants/settingsNav'
import ContentFilter from './forms/content-filter.vue'
import _cloneDeep from 'lodash.clonedeep'
const navForItem = ref({})
const activeTab = ref('settings')
const { $router, $store, $track } = useNuxtApp()
const $route = useRoute()
const item = computed(() => $store.getters.settingsItem)
const itemType = computed(() => $route.query.settingsItemType)
const activeLink = ref('')
const navFilter = ref('')
const searchFocused = ref(false)
const searchResults = ref([])
const flatNavForItem = computed(() => {
  const flat = []
  for (const [tabName, tab] of Object.entries(navForItem.value)) {
    for (const section of tab.sections) {
      for (const link of section.links) {
        flat.push({ ...link, section: section.label, tab: tabName })
      }
    }
  }
  return flat
})

const user = computed(() => $store.getters.user)
const tabs = computed(() => {
  const keys = Object.keys(settingsNav[itemType.value] || {})

  /*
   * Removing this logic because we do have customers without access to Blueprints whom we
   * still want to show some items under "Blueprint Data" for (like `Media Sources`). - GT
   */
  // if (!user.value?.capabilities?.Blueprints && keys.includes('blueprints')) {
  //   keys.splice(keys.indexOf('blueprints'), 1)
  // }
  return keys
})

const filterNode = (node) => {
  if (node.validChannels) {
    if (itemType.value === 'account') {
      const itemChannels = item.value.activeChannels || []
      if (!itemChannels.some(ch => node.validChannels.includes(ch))) {
        return false
      }
    } else {
      if (!node.validChannels.includes(item.value.advertisingChannelId)) {
        return false
      }
    }
  }
  if (node.validPartners) {
    const itemPartnerIds = item.value.partnerObjects?.map(o => o.partnerId) || []
    if (!itemPartnerIds.some(id => node.validPartners.includes(id))) {
      return false
    }
  }
  if (node.mustHaveCapabilities) {
    if (!node.mustHaveCapabilities.every(c => item.value.capabilities[c])) {
      return false
    }
  }
  if (node.mustHaveUserCapabilities) {
    if (!node.mustHaveUserCapabilities.every(c => user.value.capabilities[c])) {
      return false
    }
  }
  if (node.mustHaveSettingsItemKeys) {
    if (!node.mustHaveSettingsItemKeys.every(key => item.value[key])) {
      return false
    }
  }
  return true
}

const trimNavForItem = () => {
  const nav = _cloneDeep(settingsNav[itemType.value])
  for (const tab of Object.values(nav)) {
    tab.sections = tab.sections.filter(filterNode)
    for (const section of tab.sections) {
      section.links = section.links.filter(filterNode)
    }
    tab.sections = tab.sections.filter(section => section.links.length > 0)
  }
  navForItem.value = nav
}

const handleLinkClick = (link) => {
  const query = { ...$route.query, ...link.query }
  if ($route.query.content && !link.query.content) {
    delete query.content
  }
  $router.push({ query })
}

const getActiveLink = (query) => {
  let defaultLink = { defaultPriority: 100, label: '' }
  for (const link of flatNavForItem.value) {
    const tabName = link.tab
    if (link.query.content && link.query.content === query.content && link.query.form === query.form) {
      defaultLink = link
      if (activeTab.value !== tabName) {
        activeTab.value = tabName
      }
      break
    } else if (!query.content && link.query.form === query.form) {
      defaultLink = link
      if (activeTab.value !== tabName) {
        activeTab.value = tabName
      }
      break
    } else if (tabName === activeTab.value && !query.form && link.defaultPriority && link.defaultPriority < defaultLink.defaultPriority) {
      defaultLink = link
    }
  }
  activeLink.value = defaultLink.label
  $store.commit('activeSettingsLink', defaultLink)
}

const searchNav = (keyword) => {
  if (keyword.length > 2) {
    searchResults.value = flatNavForItem.value.filter(link => {
      if (link.label.toLowerCase().includes(keyword.toLowerCase())) {
        return true
      }
      if (link.searchTerms) {
        for (const st of link.searchTerms) {
          if (typeof st === 'string' && st.toLowerCase().includes(keyword.toLowerCase())) {
            return true
          }
          if (typeof st === 'object' && st.text.toLowerCase().includes(keyword.toLowerCase()) && filterNode(st)) {
            return true
          }
        }
      }
      return false
    })
    $track.event('settings drawer search', {
      input: keyword,
      foundResults: searchResults.value.length > 0
    })
  } else {
    searchResults.value = []
  }
}

watch(
  () => item.value,
  (value) => {
    if (value) {
      trimNavForItem()
    }
  },
  { immediate: true }
)

watch(
  () => $route.query,
  (value) => getActiveLink(value),
  { immediate: true, deep: true }
)

watch(
  () => navFilter.value,
  (value) => {
    searchNav(value)
  }
)

onBeforeUnmount(() => $store.commit('activeSettingsLink', {}))

const iconMap = {
  settings: 'toolbox',
  targeting: 'target',
  blueprints: 'blueprints'
}
</script>

<template>
  <div class="pt-3 pb-1 px-4 sticky-top" style="width: 266px; min-width: 266px;">
    <h4 class="mb-3 d-flex align-items-end" style="height: 32px;">
      <span>{{navForItem[activeTab].label}}</span>
    </h4>

    <div class="mb-4 position-relative">
      <content-filter v-model="navFilter" prepend-icon="search" placeholder=" " :debounce="400" @focus="searchFocused = true" @blur="searchFocused = false"></content-filter>
      <transition name="fade">
        <div v-if="searchFocused && searchResults.length > 0" class="bg-white w-100 p-4 position-absolute border" style="top: 100%; left: 0; z-index: 2;">
          <ul class="pl-0">
            <li v-for="link in searchResults" :key="link.label" class="list-unstyled">
              <a class="py-3 pl-3 cursor-pointer w-100 d-block text-body hover-darker rounded" @click="handleLinkClick(link)">{{link.label}}</a>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <b-form-radio-group v-if="tabs.length > 1"
                        :checked="activeTab"
                        @input="activeTab = $event"
                        class="w-100 mb-4 btn-group-toggle-radio ml-auto data-explorer-action-panel-tabs"
                        button-variant="muted-active-outline"
                        buttons>
      <b-form-radio v-for="tab in tabs" :key="tab" :value="tab">
        <fluency-icon :type="iconMap[tab]"></fluency-icon>
      </b-form-radio>
    </b-form-radio-group>

    <template v-for="section in navForItem[activeTab].sections" :key="section.label">
      <h5 class="bionic-nav-menu-heading">
        {{section.label}}
        <span class="flex-grow-1 border-bottom ml-3"></span>
      </h5>
      <ul class="pl-0">
        <li v-for="link in section.links" :key="link.label" class="list-unstyled">
          <a class="cursor-pointer d-block btn-bionic-nav-menu-item rounded"
             :class="{ 'selected': link.label === activeLink }"
             @click="handleLinkClick(link)">{{link.label}}</a>
        </li>
      </ul>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.hover-darker:hover {
  background-color: $bionic-300;
}
.active-bg {
  background-color: $fluency-paleblue !important;
  font-weight: 600;
}
</style>
