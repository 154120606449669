<template>
  <t-modal v-model="visible"
           v-bind="modalProps"
           :id="prompt.promptId"
           @ok="modalOk"
           @hide="modalHide" >
    <p v-if="preventOk" class="alert alert-danger">Please make sure to fill out all required fields</p>
    <b-form-group v-if="!forms"
                  :label="text"
                  :description="description"
                  label-for="prompt-input">
      <b-form-input id="prompt-input"
                    v-model="userInputRef"
                    :placeholder="placeholder"
                    trim
                    autofocus />
    </b-form-group>
    <template v-else>
      <p v-if="description">{{description}}</p>
      <prompt-form v-model="formVals"
                   :forms="forms"
                   :form-columns="formColumns" />
    </template>
  </t-modal>
</template>

<script setup>
import usePrompt from 'core-ui/composables/usePrompt'
import PromptForm from 'core-ui/components/inputs/promptForm'
import { watch, ref, computed } from 'vue'

const prompt = usePrompt()

const visible = prompt.modalVisible

const text = computed(() => prompt.globalOptions.value.text)
const description = computed(() => prompt.globalOptions.value.description)
const placeholder = computed(() => prompt.globalOptions.value.placeholder)
const forms = computed(() => {
  return prompt.globalOptions.value.forms
})
const formColumns = computed(() => {
  return prompt.globalOptions.value.formColumns
})

const modalProps = computed(() => {
  const { text, description, placeholder, defaultValue, forms, formColumns, ...modalProps } = prompt.globalOptions.value
  return modalProps
})

const preventOk = ref(false)
const userInputRef = ref('')
const formVals = ref({})

watch(prompt.globalOptions, (newOptions) => {
  const forms = (newOptions.forms || [])
  formVals.value = forms.reduce((accumulator, current) => ({ ...accumulator, [current.name]: current.default || '' }), {})

  userInputRef.value = newOptions.defaultValue
}, { immediate: true })

const modalHide = () => {
  if (!preventOk.value) {
    prompt.asyncResolve.value(false)
  }
}
const modalOk = (modalEvent) => {
  preventOk.value = false
  if (forms.value) {
    let reqFieldsFails = false

    Object.entries(forms.value).forEach(entry => {
      const v = entry[1]
      if (v.required) {
        if (!formVals.value[v.name]) {
          reqFieldsFails = true
        }
      }
    })
    if (reqFieldsFails) {
      preventOk.value = true
      modalEvent.preventDefault()
    } else {
      prompt.asyncResolve.value(formVals.value)
    }
  } else {
    prompt.asyncResolve.value(userInputRef.value)
  }
}

</script>
<style lang='scss' scoped>

</style>
